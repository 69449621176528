###*
# @file Header
#
# Controls the color changes on the logo and hamburger, and the fancy animations on the purpose logo lettering.
#
###

import { TweenLite } from '../vendor/gsap/all'

class Header

  constructor: ->
    __Purpose.register_barba_event_callback
      func: @init
      event: 'transitionCompleted'
    __Purpose.register_scroll_function 'header', @scroll

    $(document)
    .on 'mouseenter', '.easter-egg', ->
      $(this).addClass 'flipper-on'
    .on 'mouseleave', '.easter-egg', ->
      $(this).removeClass 'flipper-on'

    @duration = 0.3
    @delay = 0.01


  init: =>
    @header = $("header")
    @header_logo = $("header .logo")
    @build_section_list()
    @scroll()


  build_section_list: =>
    rect = document.querySelector("header .logo").getBoundingClientRect()
    logo_middle_offset = rect.bottom - (0.5 * rect.height)
    @section_list = []
    section_nodes = document.querySelectorAll("section:not(.off-canvas-nav), .module, .header-white")
    section_nodes.forEach (el)=>
      $el = $(el)
      @section_list.unshift
        white: $el.hasClass 'header-white'
        top: $el.offset().top - logo_middle_offset
    #now account for the footer which requires a black logo
    last_node = section_nodes[ section_nodes.length - 1 ]
    @section_list.unshift
      white: false
      top: ( $(last_node).offset().top + last_node.getBoundingClientRect().height ) - logo_middle_offset


  logo_out: ->
    @header.addClass 'op0'
    for i, j in $("g.logo-main-group .logo-letter")
      TweenLite.to $(i), @duration,
        opacity: 0
        scale: 0.9
        x: -10 * j
        delay: @delay * j

  logo_in: ->
    @header.removeClass 'op0'
    for i, j in $("g.logo-main-group .logo-letter")
      TweenLite.to $(i), @duration,
        opacity:1
        scale: 1
        x: 0
        delay: @delay * j


  scroll: =>
    if __Purpose.scroll_direction is 'down' and $(window).scrollTop() > 100
      @logo_out()
    else
      @logo_in()
    offset = @header_logo.offset().top
    for section, index in @section_list
      if offset > section.top
        if section.white
          @header.removeClass 'black'
        else
          @header.addClass 'black'
        return



export default Header
