###*
# @file XFadeTransition
#
# Provides the transition effect for BarbaJS that fades between pages.
###

import Barba from 'barba.js'

BaseTransition = Barba.BaseTransition



FadeTransition = Object.assign BaseTransition,

  start: ->
    #
    # This function is automatically called as soon the Transition starts
    #  this.newContainerLoading is a Promise for the loading of the new container
    #

    __Purpose.update_foundation(true) #destroys all foundation instances

    _ = this
    # As soon the loading is finished and the old page is faded out, let's fade the new page
    Promise.all([@newContainerLoading, @fadeOut.bind(this)()]).then ->
      $("html,body").scrollTop(0)
      $el = $(_.newContainer)
      $el.addClass 'transition-active'
      .css
        visibility : 'visible'
      .removeClass 'op0'
      console.log 'Fading in new content'
      setTimeout _.finish.bind( _, $el ), 500


  fadeOut: ->
    new Promise (resolve,reject)=>
      console.log 'Fading out current content'
      $(@oldContainer).addClass('op0')
      # expects the controller JS to be available as window.__Purpose
      setTimeout resolve, 500

  finish: ($el)->
    $el.removeClass 'transition-active'
    @done()




export default FadeTransition
