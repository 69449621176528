###*
# @file Carousel
###

import '@accessible360/accessible-slick/slick/slick'

class Carousel

  constructor: ->
    __Purpose.register_barba_event_callback
      func: @init
      event: 'newPageReady'


  init: =>

    Foundation.MediaQuery._init()

    $("[data-slick-carousel]:not(.slick-initialized)").slick
      arrows: false
      autoplay: false
      dots: true
      mobileFirst: true
      pauseOnFocus: false
      pauseOnHover: false
      slidesToScroll: 1
      slidesToShow: 1

    document.querySelectorAll(".carousel")?.forEach (el)=>
      $el = $(el)
      color = $el.data 'dots-color'
      $el.find('.slick-dots li button').css 'color', color


export default Carousel
