###
# @file RecaptchaSupport
#
# Salesforce web-to-lead forms depend on a custom function that updates a timestamp value on the form every 500 ms
#
###

equal = require 'deep-equal'

class RecaptchaSupport

  constructor: ()->
    @loaded = false
    window.onloadCallback = =>
      @loaded = true
      console.log 'recaptcha loaded'
    __Purpose.register_barba_event_callback
      func: @init_if_loaded
      event: 'transitionCompleted'
    @interval = false

  init_if_loaded: =>
    if @loaded
      @init()
    else
      setTimeout(@init_if_loaded, 500)

  init: ()=>
    clearInterval @interval
    $recaptcha = $('.g-recaptcha')
    @has_recaptcha = $recaptcha.length
    if @has_recaptcha
      sitekey = $recaptcha.data('sitekey')
      grecaptcha.render(
        $recaptcha[0],
        { sitekey: sitekey }
      )
      @interval = setInterval @timestamper, 500

  timestamper: ->
    return unless $('input[name=captcha_settings]').length #during the transition this will already be gone
    response = document.getElementById("g-recaptcha-response")
    if !response? or response.value.trim() is ""
      elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value)
      elems["ts"] = JSON.stringify(new Date().getTime())
      document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems)




export default RecaptchaSupport
