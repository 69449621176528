import '../scss/app.scss'

window.$ = window.jQuery = require 'jquery'

# import LanguageCheck from 'language-autoselect' # This is a hack that mimics some of the WPML plugin's functionality without the endless cruft.

import Barba from 'barba.js'

import { throttle } from 'throttle-debounce'

import { TweenLite } from './vendor/gsap/all.js'

# Set up foundation dependencies
import { Foundation } from 'foundation-sites/js/foundation.core.js'

# Foundation Utils
import { Box } from 'foundation-sites/js/foundation.util.box.js'
# import { imageLoader } from 'foundation-sites/js/foundation.util.imageLoader.js'
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard.js'
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery.js'
import { Triggers } from 'foundation-sites/js/foundation.util.triggers.js'
import { Touch } from 'foundation-sites/js/foundation.util.touch.js'
import { Motion } from 'foundation-sites/js/foundation.util.motion.js'
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js'

# Foundation Plugins core
import { Abide } from 'foundation-sites/js/foundation.abide.js'
import { Accordion } from 'foundation-sites/js/foundation.accordion.js'
import { Dropdown } from 'foundation-sites/js/foundation.dropdown.js'
import { Equalizer } from 'foundation-sites/js/foundation.equalizer.js'
import { Interchange } from 'foundation-sites/js/foundation.interchange.js'
import { Magellan } from 'foundation-sites/js/foundation.magellan.js'
import { Sticky } from 'foundation-sites/js/foundation.sticky.js'
import { Reveal } from 'foundation-sites/js/foundation.reveal.js'

foundation_plugins =
  Abide: Abide
  Accordion: Accordion
  Dropdown: Dropdown
  Equalizer: Equalizer
  Interchange: Interchange
  Magellan: Magellan
  Sticky: Sticky
  Reveal: Reveal

Foundation.MediaQuery = MediaQuery
Foundation.Triggers = Triggers
# Foundation.imageLoader = imageLoader
Foundation.Keyboard = Keyboard
Foundation.SmoothScroll = SmoothScroll
Foundation.Abide = Abide
Foundation.Equalizer = Equalizer
Foundation.Interchange = Interchange
Foundation.Accordion = Accordion
Foundation.Dropdown = Dropdown
Foundation.Magellan = Magellan
Foundation.Sticky = Sticky
Foundation.Reveal = Reveal

Foundation.addToJquery($)

import WhatInput from 'what-input'

# Custom modules
import Carousel from './modules/Carousel'
import FadeTransition from './modules/XFadeTransition'
import Header from './modules/Header'
import RecaptchaSupport from './modules/RecaptchaSupport'


require './vendor/CookieConsent.js'

class Purpose
  constructor: ( config )->
    unless window.console?
      window.console =
        log: ->
    @Foundation = Foundation
    @foundation_plugins = config.foundation_plugins
    @barba_event_callbacks = []
    @onscroll_callbacks = {}
    @ajaxurl = "/wp-content/themes/purpose2018/ajax/admin-ajax.php"
    @scroll_direction = 'down'
    @last_scrollTop = 0

    console.log "This site was built by"
    console.log " ____  _                   "
    console.log "|___ \\| |                  "
    console.log "  __) | |_ ___  _ __   ___ "
    console.log " |__ <| __/ _ \\| '_ \\ / _ \\"
    console.log " ___) | || (_) | | | |  __/"
    console.log "|____/ \\__\\___/|_| |_|\\___|"
    console.log ""
    console.log "Get in touch: info@3-tone.com"
    console.log "============================="
    console.log "============================="


    $(document).ready =>
      @run_once()
      window.jQuery = $

  ga_log_event: (category, action, label, cb = ->) ->
    console.log "LOG: #{category} - #{action} - #{label}"
    if category and action and label and typeof gtag isnt 'undefined'
      gtag 'event', action,
        'event_category': category
        'event_label': label
        'event_callback': cb

  ga_track_pageview: (page_url) ->
    console.log 'Tracking pageview for ' + page_url
    if typeof gtag is 'function'
      gtag 'config', GA_TRACKING_ID,
        page_location: page_url
    if typeof fbq is 'function'
      fbq 'track', 'PageView'



  hover_group_in: (e) ->
    $(e.currentTarget).parents('[data-hover-group]').find('a').addClass 'active'

  hover_group_out: (e) ->
    $(e.currentTarget).parents('[data-hover-group]').find('a').removeClass 'active'


  is_ios: ->
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  ios_version: ->
    parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1]).replace('undefined', '3_2').replace('_', '.').replace('_', '')) || false


  navigation_close: =>
    $('html, body').removeClass 'navigation-expanded'
    $("[data-contact], [data-newsletter]").hide()
    $('footer').show()
    $('body, html').css('height', "auto")
    @Clocks.reset_clocks()

  navigation_open: (action = false)=>
    s = 0.7
    x = 20

    if $(window).width() > 1023
      aux1_delay = 0
      aux2_delay = 0.2
      main_delay = 0.4
    else
      aux1_delay = 0.2
      aux2_delay = 0.4
      main_delay = 0
    # $('#menu-aux-links-1 li').each (a,b)->
    #   TweenLite.fromTo b, s, {y:'-50px',opacity:0}, {y:'0px',opacity:1,delay:a/x+aux1_delay}
    # $('#menu-aux-links-2 li, .equity-aux-nav').each (a,b)->
    #   TweenLite.fromTo b, s, {y:'-50px',opacity:0}, {y:'0px',opacity:1,delay:a/x+aux2_delay}
    # $('#menu-main li').each (a,b)->
    #   TweenLite.fromTo b, s, {y:'-50px',opacity:0}, {y:'0px',opacity:1,delay:a/x+main_delay}
    # $('.clocks-container li').each (a,b)->
    #   TweenLite.fromTo b, s, {y:'-50px',opacity:0}, {y:'0px',opacity:1,delay:a/x+0.6}
    $('body, html').css('height', "#{$('.off-canvas-nav').outerHeight()}px")
    $('html, body').addClass 'navigation-expanded'
    @Clocks.set_up_clocks()
    $('footer').hide()

    if action == 'show_newsletter'
      $("[data-newsletter]").show()

  navigation_toggle: =>
    if $('body').hasClass('navigation-expanded') then @navigation_close() else @navigation_open()

  newsletter_check: =>
    if window.location.hash == "#newsletter"
      @navigation_open 'show_newsletter'
  ###
  #
  # Handles Barba's newPageReady event
  #
  ###
  new_page_ready: (currentStatus, prevStatus, HTMLElementContainer, newPageRawHTML)=>
    #console.log "Barba:newPageReady"
    #this is also set in the FadeTransition but there's a race condition so we do it in both places
    HTMLElementContainer.style.visibility = "visible"
    $(document).foundation()
    @ga_track_pageview currentStatus.url
    @update_body_classes_from_barba newPageRawHTML
    @replace_wp_admin_bar HTMLElementContainer
    @update_foundation()
    @set_footer_spacer_height()
    callback.func.call this for callback in @barba_event_callbacks when callback.event is 'newPageReady'


  off_canvas_menu_clicks: (e) ->
    # if e.currentTarget.attributes.href.value is '#contact'
    #   $("[data-contact]").show()
    #   $("[data-newsletter]").hide()
    #   e.preventDefault()
    if e.currentTarget.attributes.href.value is '#newsletter'
      $("[data-contact]").hide()
      $("[data-newsletter]").show()
      e.preventDefault()
    null

  ###
  #
  # The payload is an object with two properties:
  # @event string - the name of the barbaJS for which to trigger the function
  # @func function - the function to call
  #
  ###
  register_barba_event_callback: (payload)=>
    @barba_event_callbacks.push payload


  register_scroll_function: (key, func) =>
    if func is false
      delete @onscroll_callbacks[key]
    else
      @onscroll_callbacks[key] = func


  # The wp-admin bar for logged-in users appears outside the barba container so we need to do some extra work
  replace_wp_admin_bar: (HTMLElementContainer) ->
    n = $(HTMLElementContainer).find('#wpadminbar').detach()
    o = $('#wpadminbar')
    if n.length
      if o.length
        o.replaceWith n
      else
        $('body').append n


  run_once: =>
    # LanguageCheck()
    $("[data-interchange]").each ->
      new Foundation.Interchange $(this)
    window.cookieconsent.initialise
      palette:
        popup:
          background: "#f8f9fa"
          text: "#222222"
        button:
          background: "#2354fb"
          text: "#ffffff"
      position: "bottom"
      content:
        message: "<p><span><strong>We use cookies</strong> to ensure you get the best experience on our website.</span>These may include cookies from Google Analytics and Facebook. By using this website, you consent to the use of these cookies. <a aria-label='learn more about cookies' role='button' tabindex='0' href='/privacy-policy' rel='noopener noreferrer nofollow' target='_blank'>Learn more</a></p>"
        dismiss: "Thanks, got it!"
        link: "Learn more"
        href: "/privacy-policy"

    window.p__ = (txt)->
      if window.$__purpose_translations
        window.$__purpose_translations[txt]
      else
        txt

    $(document)
    .on 'click', '[data-offcanvas-nav]', @navigation_toggle
    .on 'click', '.secondary-nav a, .primary-nav a', @off_canvas_menu_clicks
    .on 'click', '[data-play-video]', @show_video
    .on 'mouseenter', 'body.not-ios [data-hover-trigger]', @hover_group_in
    .on 'mouseleave', 'body.not-ios [data-hover-trigger]', @hover_group_out
    .find('.barba-container').removeClass 'op0'

    $(window)
    .on 'resize', =>
      @set_footer_spacer_height()
    .on 'scroll', throttle(30, @scroll)

    # @Awards = new Awards
    # @ApplyClassOnScroll = new ApplyClassOnScroll('fadeInUp animated', 75, "body.single-case_studies section:not(.off-canvas-nav) h2, \
    #   body.single-case_studies section:not(.off-canvas-nav) h3, \
    #   body.single-case_studies section:not(.off-canvas-nav) h4, \
    #   body.single-case_studies section:not(.off-canvas-nav) p:not([data-no-fadein]), \
    #   [data-fadein]")
    # @BackgroundVideo = new BackgroundVideo
    #   large: "/wp-content/uploads/2019/02/Banner_V9_fs.mp4"
    #   small: "/wp-content/uploads/2019/02/Banner_V9_fs.mp4"
    #   target: ".video-attach"
    @Carousel = new Carousel
    # @CaseStudies = new CaseStudies
    # @Clocks = new Clocks
    # @Footer = new Footer
    @Header = new Header unless $('body.page-template-templatestemplate-brazil-climate-individual-php').length
    # @Partners = new Partners
    # @AboutPage = new AboutPage
    # @JobsPage = new JobsPage
    # @NewsPage = new NewsPage
    @RecaptchaSupport = new RecaptchaSupport
    # @WorkPage = new WorkPage
    # @TeamPage = new TeamPage
    # @SearchAnimation = new SearchAnimation
    # @NewsletterHeader = new Newsletter
    # @TenYears = new TenYears
    # @ShowMore = new ShowMore

    @register_barba_event_callback({event: 'newPageReady', func: @newsletter_check})

    Barba.Dispatcher.on 'newPageReady', @new_page_ready
    Barba.Dispatcher.on 'transitionCompleted', @transition_completed

    Barba.Pjax.getTransition = ->
      FadeTransition
    Barba.Pjax.cacheEnabled = false

    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck

    Barba.Pjax.preventCheck = (evt, element) ->
      if( !Barba.Pjax.originalPreventCheck evt, element )
        return false
      if( /wp-admin/.test(element.href.toLowerCase()) )
        return false
      true

    Barba.Pjax.start()


  set_footer_spacer_height: ->
    setTimeout ->
      fs = $(".footer-spacer")
      if fs.length > 0
        fca = document.querySelectorAll('.footer-content')
        fc = fca[ fca.length - 1 ]
        $(".footer-spacer").css 'height', ( fc.getBoundingClientRect().height + 'px' )


  scroll: =>
    s = $(window).scrollTop()
    @scroll_direction = if s > @last_scrollTop then 'down' else 'up'
    @last_scrollTop = s

    for key, func of @onscroll_callbacks
      func()



  show_video: (e)->
    e.preventDefault()
    $target = $(e.currentTarget)
    emb = $($target.data('playVideo'))
    vs = new URL(emb.attr 'src')
    vs.searchParams.set 'autoplay', 'true'
    emb.attr 'src', vs.toString()
    $target
    .parent().hide()
    .end()
    .parents('.video-container').append emb



  transition_completed: =>
    #console.log "Barba::transitionCompleted"
    callback.func.call this for callback in @barba_event_callbacks when callback.event is 'transitionCompleted'

    ###
    #
    # the following is necessary because Magellan's initial calculation of offsets will be wrong if the page was
    # loaded via Barba, since the new HTML is inserted after the existing html and exists there during foundation init
    #
    ###
    $('[data-magellan]').each ->
      $(this).foundation 'reflow'



  update_body_classes_from_barba: (newPageRawHTML)->
    cl = newPageRawHTML.match(/body class="(.*)"/)[1]
    $('body')
    .removeClass()
    .addClass cl
    $('body').addClass 'not-ios' unless @is_ios()
    #console.log "Barba: updating body classes to #{cl}"



  update_foundation: (destroy = false)=>
    if destroy
      console.log "Destroying foundation plugins"
      for name, plugin of @foundation_plugins
        try
          $("[data-#{name.toLowerCase()}]").each ->
            $(this).foundation('_destroy')
        catch error

      $("[data-slick-carousel]").each ->
        $(this).slick 'unslick'
    else
      console.log "Initializing foundation plugins"
      for name, plugin of @foundation_plugins
        $("[data-#{name.toLowerCase()}]").each ->
          new plugin $(this)
      $.triggersInitialized = false
      @Carousel.init()
      Foundation.Triggers.init($) #setting triggersInitialized to false ensures that MutationObservers will be set on new DOM elements


window.__Purpose = new Purpose
  foundation_plugins: foundation_plugins
